<template>
  <div
    :tabindex="tabIndex"
    class="slide b-black">
    <h2 class="b-blue"> Let's get to work <span class="t-black">.</span></h2>
    <div>
      <p>
        The most important ingredient is to get up and do something. It's as simple as that. Many people have ideas, but very few decide to do something with them, now. Not tomorrow. Not next week. Today. The true entrepreneur is a doer, not a dreamer.
        <br>
        <br>
      </p>
      <h3>Some projects I have been a part of<span></span> ⤵️ </h3>
      <div class="image-slide">
        <a
          v-for="(item, i) in imgList"
          :key="i"
          :href="item.link"
          target="_blank">
          <img
            :src="item.src"
            :alt="item.title"
            height="113"
            width="200">
          <label>{{ item.title }}</label>
        </a>
      </div>
    </div>
    <div class="disclaimer">
    </div>
    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      :direction="'up'"
      @keydown.enter="updateSlide('prev')"
      @keydown.space="updateSlide('prev')"
      @click="updateSlide('prev')"/>
    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      @click="updateSlide()"/>
  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMethods'

export default {
  name: 'MyProjectsSlide',
  mixins: [slideMixin],
  data () {
    return {
      age: new Date(Date.now() - new Date('11/1/17').getTime()).getFullYear() - 1970,
      imgList: [
        // {
        // src: require('@/assets/img/cybdash.jpg'),
        // link: 'https://cybersecuritydashboard.vercel.app/dashboard',
        // title: 'Cybersecurity Dashboard'
        // },
        {
          src: require('@/assets/img/papers.jpg'),
          link: 'https://www.researchgate.net/profile/Diego-Staino',
          title: 'Papers & Research'
        },
        {
          src: require('@/assets/img/newsletter.jpg'),
          link: 'https://www.linkedin.com/newsletters/writing-cybersecurity-coffee-7024719960866201600/',
          title: 'Writing, CybSec & Coffee'
        },
        {
          src: require('@/assets/img/t3sf.png'),
          link: 'https://github.com/Base4Security/T3SF',
          title: 'Table Top Exercises'
        },
        {
          src: require('@/assets/img/dolost.jpg'),
          link: 'https://github.com/Base4Security/DOLOS-T',
          title: 'DolosT'
        }
      ]
    }
  },
  computed: {
    tabIndex () {
      return this.actualSlide === this.slideValue ? '1' : '-1'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';

img {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.65);
  margin: 0 1em;
  transition: transform .3s ease;
  border-radius: 10%;
  max-width: 45%;
  max-height: 45%;
}

.slide p {
  margin-bottom: 0;
  font-size: 1.4em;
}

.slide h3 {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;

  span {
    position: absolute;
    font-size: .4em;
    right: 12px;
  }
}

.slide h2 {
  font-size: 3em;
}

.image-slide {
  display: flex;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    text-decoration: none;

    img:hover + label::before {
      width: 100%;
    }

    label {
      margin-top: .7em;
      font-size: 1.2em;
      position: relative;
      padding: 0 3px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 0;
        background-color: var(--yellow);
        height: 100%;
        transition: width 0.3s cubic-bezier(.22,.68,0,1.2);
        z-index: -1;
      }

      &:hover {
        cursor: pointer;

        &::before {
          width: 100%;
        }
      }
    }
  }
}

p {
  margin-bottom: 0;
}

h3 {
  margin-top: 0;
}

.disclaimer {
  font-size: .8em;
}

@media screen and (max-width: $tablet-breakpoint) {
  .slide {
    .image-slide {
      max-width: 100%;
      flex-wrap: wrap;

      a {
        width: 100%;

        label {
          background-color: var(--yellow);
          font-weight: bold;
          padding: 5px;
          letter-spacing: 0.05em;

          &::before {
            display: none;
          }
        }
      }

      img {
        display: none;
      }
    }

    h3 {
      span {
        right: 8px;
      }
    }
  }

  .slide .arrow-container.arrow-down {
      bottom: 3.5em;
  }
}
</style>
