<template>
  <div
    :tabindex="tabIndex"
    class="slide b-black">
    <div class="floating-icon left-icon">{{ leftIcon }}</div>
    <div class="floating-icon right-icon">{{ rightIcon }}</div>
    <h2 class="b-blue">About Me<span class="t-black">.</span></h2>
    <div>
      <p>
        Hey! I'm Diego Staino
      </p>
      <br>
      <p>
        Currently I'm working in BASE4 Security as
      </p>
      <p>
        <b class="b-blue">Cybersecurity Innovation Leader</b>
      </p>
      <p>
      <br>
      </p>
      <p>
      <br>
        #Policies #CyberRisk #DisasterRecoveryPlan #DefenseInDepth
        #IT #TTX #Awareness
        #NISTCSF #MitreAtt&ck #MitreEngage #CloudSec
        #Python #Javascript #Powershell #CodeAsAHobbie
      </p>
      <br>
      <p class="b-blue">
        #Methodical #Innovative #Advisor
      </p>
    </div>
    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      :direction="'up'"
      @keydown.enter="updateSlide('prev')"
      @keydown.space="updateSlide('prev')"
      @click="updateSlide('prev')"/>
    <ChangeSlide
      :actualSlide="actualSlide"
      :slideValue="slideValue"
      @click="updateSlide()"/>
  </div>
</template>

<script>
import slideMixin from '@/mixins/slideMethods'

export default {
  name: 'AboutMeSlide',
  mixins: [slideMixin],
  data () {
    return {
      leftIcon: '🌱',
      rightIcon: '💾'
    }
  },
  computed: {
    tabIndex () {
      return this.actualSlide === this.slideValue ? '1' : '-1'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables.scss';

.slide h2 {
  font-size: 3em;
}

.floating-icon {
  $rotation: 20deg;
  $position: 8vw;
  position: absolute;
  font-size: 7em;

  &.left-icon {
    transform: rotate($rotation * -1);
    left: $position;
  }

  &.right-icon {
    transform: rotate($rotation);
    right: $position;
  }
}

@media screen and (max-width: $tablet-breakpoint) {
  .slide .floating-icon {
    $position: 5vh;
    font-size: 4em;
    text-align: center;
    left: 0;
    right: 0;

    &.left-icon {
      display: none;
    }
    &.right-icon {
      bottom: $position;
    }
  }
}

.slide p {
  margin-bottom: 0;
  font-size: 1.4em;
}
</style>
